import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha } from '@mui/system/colorManipulator';

import { LanguageSwitch } from '../language-switch';
import { useRouter } from '../../../hooks/use-router';
import { Logo } from 'src/components/logos/logo';
import { useSettings } from 'src/hooks/use-settings';
import { Typography } from '@mui/material';

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

export const TopNav = (props) => {
  const { onMobileNavOpen, ...other } = props;
  const router = useRouter();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const hostname = window?.location?.hostname;
  const settings = useSettings();

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
        },
        px: { md: 4, xs: 2 },
        zIndex: 15000,
      }}
      {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          py: 2,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          {!lgUp && (
            <Stack spacing={1} alignItems="center" direction="row">
              {hostname === "octolit.link" ? (
                <img src="/assets/logos/logo-link.svg" width={30} />
              ) : hostname === "octolit.com" ? (
                <img src="/assets/logos/default_logo.svg" width={30} />
              ) : (
                <SvgIcon sx={{ height: 24, width: 24 }}>
                  <Logo color={settings?.colorPreset} />
                </SvgIcon>
              )}
              <Typography sx={{
                fontSize: 14,
                fontWeight: 800,
                letterSpacing: "0.3px",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                color: "text.primary"
              }}>
                OCTOLIT
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <LanguageSwitch />
          <Button variant='contained'
            onClick={() => router.push('/contact_us')}>Contact Us</Button>
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};
